import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TeamsService } from 'weavix-shared/services/teams.service';

@Component({
    selector: 'app-logo-spinner',
    templateUrl: './logo-spinner.component.html',
    styleUrls: ['./logo-spinner.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
    ],
})
export class LogoSpinnerComponent {
    @Input() size: number;
    @Input() diameter = 30;
    @Input() slow: boolean;
    @Input() showMessage = true;

    constructor(public teamsService: TeamsService) { }
}
