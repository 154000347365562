<div class="banner" [ngClass]="{ 'banner-red': totalErrors > 0, 'banner-green': totalErrors <= 0 }">
    <app-icon
        faIcon="fa-light {{totalErrors > 0 ? 'fa-circle-exclamation' : 'fa-circle-check'}}"
        class="banner-icon"
    ></app-icon>
    <div class="banner-text">
        <span *ngIf="totalErrors | appErrorOrOk:errorTitle:okTitle as title" class="banner-text-title">{{title}}</span>
        <span *ngIf="totalErrors | appErrorOrOk:errorSubtitle:okSubtitle as subtitle" class="banner-text-subtitle">{{subtitle}}</span>
    </div>
    <div *ngIf="totalErrors > 0 && !noArrows" class="banner-arrows">
        <span class="banner-arrows-text">{{ 'generics.i-of-n' | translate:{ i: currentError, n: totalErrors } }}</span>
        <button (click)="previousError.emit()" class="banner-arrows-button">
            <app-icon faIcon="fa-light fa-chevron-left" class="banner-arrows-button-icon"></app-icon>
        </button>
        <button (click)="nextError.emit()" class="banner-arrows-button">
            <app-icon faIcon="fa-light fa-chevron-right" class="banner-arrows-button-icon"></app-icon>
        </button>
    </div>
</div>
