import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';
import { merge } from 'lodash';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

export interface FormButtonOptions {
    submitDisabled?: boolean;
    showSubmitButton?: boolean;
    showCancelButton?: boolean;
    showGoBackButton?: boolean;
    showDeleteButton?: boolean;
    submitTextKey?: string;
    deleteTextKey?: string;
    submitButtonDropdown?: SubmitButtonDropdown[];
    actionButton?: {
        textKey: string;
        class?: string;
        action: () => void;
    };
}

export interface SubmitButtonDropdown {
    textKey: string;
    action: () => void;
}

@Component({
    selector: 'app-form-buttons',
    templateUrl: './form-buttons.component.html',
    styleUrls: ['./form-buttons.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatMenuModule,
        TranslateModule,

        IconComponent,
    ],
})
export class FormButtonsComponent implements OnChanges, OnInit {
    static defaultOptions: Partial<FormButtonOptions> = {
        showSubmitButton: true,
        showCancelButton: true,
        showGoBackButton: false,
        showDeleteButton: false,
    };

    @Input() options: FormButtonOptions;
    @Input() submitDisabled: boolean;
    @Input() showCancelButton: boolean;
    @Input() showSubmitButton: boolean;
    @Input() showGoBackButton: boolean;
    @Input() showDeleteButton: boolean;
    @Input() enterSubmits: boolean;
    @Input() submitTextKey: string;
    @Input() deleteTextKey: string;
    @Input() submitButtonDropdown: SubmitButtonDropdown[];
    @Input() actionButton: FormButtonOptions['actionButton'];
    @Output() submitOutput: EventEmitter<void> = new EventEmitter();
    @Output() deleteOutput: EventEmitter<void> = new EventEmitter();
    @Output() cancelOutput: EventEmitter<void> = new EventEmitter();
    @Output() backOutput: EventEmitter<void> = new EventEmitter();

    ngOnInit() {
        this.updateOptions();
    }

    ngOnChanges() {
        this.updateOptions();
    }

    private updateOptions() {
        this.options = merge(
            {}, // lodash merge mutates first object
            FormButtonsComponent.defaultOptions,
            this.options,
            {
                submitDisabled: this.submitDisabled,
                showCancelButton: this.showCancelButton,
                showSubmitButton: this.showSubmitButton,
                showGoBackButton: this.showGoBackButton,
                showDeleteButton: this.showDeleteButton,
                actionButton: this.actionButton,
                enterSubmits: this.enterSubmits,
                submitTextKey: this.submitTextKey,
                deleteTextKey: this.deleteTextKey,
                submitButtonDropdown: this.submitButtonDropdown,
            },
        );
    }
}
