<app-modal *ngIf="folderEditorModal" [modalInput]="folderEditorModal" (modalCloseOutput)="handleModalClose($event)">
    <form class="form flexGrow1" [formGroup]="form">
        <div class="fields-container flexGrow1">
            <div class="description mT15 mB15">{{ 'folders.add-folder.description' | translate }}</div>
            <div class="required-instructions">
                <span>{{ 'generics.required-instructions' | translate }}</span>
            </div>
            <app-input
                label="folders.add-folder.name"
                formControlName="name"
                [value]="form.controls.name.value"
                [required]="true"
                [autofocus]="true"
                errorClass="block"
            ></app-input>
            <div class="folder-permission mT20">{{ 'folders.add-folder.folder-permissions.title' | translate }}</div>
            <div class="folder-permission description mT15 mB15">{{ 'folders.add-folder.folder-permissions.description' | translate }}</div>
            <div class="folder-permission list mB20">
                <div *ngFor="let permission of permissions?.controls; let i = index;" [formGroup]="permission" class="item mT10">
                    <app-dropdown
                        [dropdownInput]="peopleTargets"
                        formControlName="target"
                        placeholder="folders.add-folder.placeholder.target"
                        floatedLabel="folders.add-folder.folder-permissions.grant-permissions-by"
                        [showLabel]="i === 0"
                        [setDefault]="false"
                        (dropdownOutput)="checkProperties(i)"
                    ></app-dropdown>
                    <app-dropdown
                        [dropdownInput]="actionPermissions"
                        formControlName="action"
                        placeholder="folders.add-folder.placeholder.action"
                        floatedLabel="folders.add-folder.folder-permissions.permission"
                        [showLabel]="i === 0"
                        [setDefault]="false"
                        (dropdownOutput)="checkProperties(i)"
                    ></app-dropdown>
                    <ng-container [ngSwitch]="isTagsDataSource(i)">
                        <app-chip-list
                            *ngSwitchCase="true"
                            formControlName="values"
                            [dataSource]="getDataSource(i)"
                            [class.mT20]="i === 0"
                            [removeSelected]="false"
                            [canAdd]="true"
                            addNewItemLabel="tags.add-tag"
                            [addNewItemFn]="addTagFn"
                            (valueChanged)="markPermissionsAsDirty()"
                        ></app-chip-list>
                        <app-chip-list
                            *ngSwitchCase="false"
                            formControlName="values"
                            [dataSource]="getDataSource(i)"
                            [class.mT20]="i === 0"
                            (valueChanged)="markPermissionsAsDirty()"
                        ></app-chip-list>
                    </ng-container>
                    
                    <div class="action-container" [class.mT20]="i === 0" >
                        <button *ngIf="isPermissionModified(i)" type="button" class="button remove p0" (click)="removePermission(i)">
                            <app-icon [faIcon]="'fas fa-trash'" class="icon"></app-icon>
                        </button>
                        <button *ngIf="i === permissions.controls.length - 1" type="button" class="button add p0" [disabled]="!isPermissionModified(i) || !isPermissionValid(i)" (click)="addPermissionRow()">
                            <app-icon [faIcon]="'fas fa-plus-circle'" class="icon"></app-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="actions">
            <app-form-buttons
                [submitDisabled]="form.invalid || form.pristine"
                (submitOutput)="submit()"
                (cancelOutput)="cancel()"
            ></app-form-buttons>
        </div>
    </form>
</app-modal>
