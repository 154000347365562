import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chip } from '../chip-list.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'components/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChipDisplayPipe } from '../chip-display.pipe';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatChipsModule,
        TranslateModule,

        ChipDisplayPipe,

        IconComponent,
    ],
})
export class ChipComponent {
    @Input() chip: Chip | string;
    @Input() displayKey = 'name';
    @Input() selectable = false;
    @Input() disabled = false;
    @Input() removable = true;
    @Input() translate = true;
    @Input() invalid = false;

    @Output() removeOutput = new EventEmitter<Chip | string>();

    handleRemove() {
        if (this.removable && !this.disabled) {
            this.removeOutput.emit(this.chip);
        }
    }
}
