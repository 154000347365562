import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { AppIcon } from 'weavix-shared/models/icon.model';
import { TableFilterResult } from 'weavix-shared/models/table.model';
import { IconComponent } from 'components/icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-table-filter-row',
    templateUrl: './table-filter-row.component.html',
    styleUrls: ['./table-filter-row.component.scss'],
    standalone: true,
    imports: [
        CommonModule,

        IconComponent,
    ],
})
export class TableFilterRowComponent {
    @Input() index: number = 0;
    @Input() result: TableFilterResult;
    @Output() clickEmit: EventEmitter<TableFilterResult> = new EventEmitter();
    @Output() selectEmit: EventEmitter<TableFilterResult> = new EventEmitter();

    lightTheme: boolean;

    constructor() {
        this.lightTheme = ThemeService.getLightTheme();
    }

    get icon(): AppIcon { return typeof this.result.icon === 'string' ? { faIcon: this.result.icon } : this.result.icon; }

    handleResClick(result: TableFilterResult): void {
        if (result?.disabled && result?.disabled()) return;
        else if (result.customClickAction) result.customClickAction();
        else this.clickEmit.emit(result);
    }

    handleSelectClick(result: TableFilterResult): void {
        if (result?.disabled && result?.disabled()) return;
        else if (result.customClickAction) result.customClickAction();
        else this.selectEmit.emit(result);
    }
}
