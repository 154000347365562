import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ClickOutsideDirective } from 'weavix-shared/directives/click-outside.directive';
import { OpenDirection } from 'weavix-shared/models/core.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

/**
 * A color picker that works with Angular forms.
 * 
 * @example
 * ```html
 * <app-color-picker [colorOptions]="['blue', 'red']" [(ngModel)]="color"></app-color-picker>
 * ```
 * or
 * ```html
 * <app-color-picker [colorOptions]="['blue', 'red']" formControlName="color"></app-color-picker>
 * ```
 */
@AutoUnsubscribe()
@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          multi: true,
          useExisting: forwardRef(() => ColorPickerComponent),
        },
    ],
    standalone: true,
    imports: [
        CommonModule,

        ClickOutsideDirective,
    ],
})
export class ColorPickerComponent implements ControlValueAccessor {
    /**
     * The array of CSS color values to display in the color picker.
     */
    @Input() colorOptions: string[] = [];
    /**
     * The number of columns to display in the color picker.
     */
    @Input() columns = 18;
    /**
     * The direction in which the color picker should open.
     */
    @Input() openDirection: OpenDirection = OpenDirection.TopLeft;

    selectedColor: string;
    showColorList: boolean = false;
    colorListWidth: number;

    disabled: boolean = false;

    constructor() {}

    writeValue(obj: any): void {
        this.selectedColor = obj;
        this.onChange(obj);
    }

    showColors(show?: boolean) {
        if (this.disabled) return;
        this.showColorList = show != null ? show : !this.showColorList;
    }

    pickColor(selectedItem: string) {
        if (this.disabled) return;
        this.writeValue(selectedItem);
        this.showColors(false);
    }

    setDisabledState(isDisabled: boolean): void {
        this.showColors(false);
        this.disabled = isDisabled;
    }

    onChange = (_: string) => {};

    onTouched = () => {};

    registerOnChange(fn: (id: string) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }

}
