import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { ToolTipService } from './tooltip.service';
import { IconComponent } from 'components/icon/icon.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

        IconComponent,
    ],
})
export class TooltipComponent {
    @Input() options: TooltipOptions;
    @Input() translate: boolean = true;

    lightTheme: boolean;
    show: boolean;

    constructor(
        private elRef: ElementRef,
        private toolTipService: ToolTipService,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        if (this.options?.showOnClick) {
            if (!this.elRef.nativeElement.contains(event.target)) {
                this.show = false;
            }
        }
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.toolTipService.setIsShowing(true);
    }
  
    @HostListener('mouseleave')
    onMouseLeave() {
        this.toolTipService.setIsShowing(false);
    }
}
