import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { LogoSpinnerComponent } from 'components/logo-spinner/logo-spinner.component';

import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        LogoSpinnerComponent,
    ],
})
export class LoadingComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() loading: boolean = false;
    @Input() numBars: number = 0;
    @Input() diameter: number;
    @Input() inline: boolean;
    @Input() theme: 'dark' | 'light' | 'transparent' = 'light';
    @Input() backgroundColor: string;

    @ViewChild('loadDiv') loadDiv: ElementRef;

    spinnerDiameter: number = 0;
    private readonly defaultSpinnerDiameter = 100;

    bars: number[] = [];

    constructor() {}

    ngOnInit() {
        this.bars = Array(this.numBars).fill(0).map((x, i) => i);
    }

    ngOnChanges() {
        this.spinnerDiameter = this.diameter || this.defaultSpinnerDiameter;
    }

    async ngAfterViewInit() {
        await this.calculateDiameter();
    }

    @HostListener('window:resize', ['$event'])
    async onResize() {
        await this.calculateDiameter();
    }

    private async calculateDiameter() {
        if (!this.inline || this.diameter) return;

        // _.has doesn't work inside nativeElement for some reason
        if (!this.loadDiv) return;
        if (!this.loadDiv.nativeElement) return;

        if (this.loadDiv.nativeElement.offsetHeight) {
            this.spinnerDiameter = Math.min(this.loadDiv.nativeElement.offsetHeight, this.loadDiv.nativeElement.offsetWidth);
        }
    }
}
